.table {
  th, td{
    padding: 0.5em 1em;;
    vertical-align: middle;
    border-bottom: none;
    border-top: none;
    &.action-details{
      width: 6em;
      i{
        color: $secondary;
      }
      &:hover{
        background-color: $black;
        i, svg{
          color: #FFF;
        }
      }
    }
    &.va-top { vertical-align: top; }
    &.va-middle { vertical-align: middle; }
    &.va-bottom { vertical-align: bottom; }
  }

  thead{
    th {
      vertical-align: bottom;
      font-size: 0.8em;
    }
  }
  tbody{
    background: #fff;
    tr{
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      td {
        padding:1em;
      }
    }
    tr:hover{

      background-color:$menu-hovered !important;
    }
  }
}