/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar{
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 64px;
  left: 0;
  bottom: 0;
  width: 250px;
  z-index: 999;
  background: #FFF;
  transition: all 0.3s;
  color: $black;
  .logo{
    position: absolute;
    bottom: 50px;
    img{
      padding: 20px;
      width: 100%;
      opacity: 20%;
    }
  }
  .sidebar-content {
    flex: 1;
    overflow: auto;
    .jstree-wrapper{
      margin-left: 0.5rem;
      .title{
        font-weight: 500;
      }
      .jstree-node{
        & > div{
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        .jstree-wholerow-hovered{
          background-color: $menu-hovered ;
        }
        .jstree-wholerow-clicked{
          background: $menu-selected ;
        }
        .jstree-clicked{
          color: $menu-text-selected;
        }
      }
    }
  }
  .sidebar-footer {
    padding: 1rem;
  }

  margin-left: -250px;
  &.show {
    margin-left: 0;
  }

  &.ui-resizable-resizing {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.main {
  width: 100%;
  padding: 64px 0;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
#sidebar.show + .main {
  width: calc(100% - 250px);
}
