.folders, .resources, .search {
  .navbar-actions{
    .details{
      &.btn-link:not(.text-secondary) {
        svg{
          stroke-width: 3;
          fill: $menu-selected;
        }
      }
      &.btn-link:not(.text-secondary):focus {
        color: $link !important;
        svg{
          color: $link !important;
        }
      }
    }
  }
  .list-folders, .list-resources{
    .folder-row, .resource-row{
      cursor: pointer;
        &.selected{
          td{
            background: $menu-selected;
            color: $menu-text-selected;
            &.action-details{
              i{
                color: $menu-text-selected;
              }
              &:hover{
                background-color: $black;
                i, svg{
                  color: #FFF;
                }
              }
            }
          }
        }
      td{
        padding: 3px 1em;
        svg{
          &.checked{
            stroke-width: 3;
          }
        }
        &.action-details{
          i{
            color: $secondary
          }
          &:hover{
            background-color: $black;
            i,svg{
              color: #FFF;
            }
          }
        }
      }
    }
  }
  .folder-details-wrapper{
    .folder-details{
      height: 100vh;
      padding-top: 1rem;
      width: 23rem;
      border-left: 1px solid $border-grey;
      h1 {
        word-break: break-word;
      }
      .icon{
        padding-top: 2rem;
        padding-bottom: 2rem;
        svg {
          color:$black;
          width: 8em;
          height: 8em;
          stroke-width: 0.5px;
        }
      }
      .folder-actions{
        a{
          font-size: 1.2rem;
        }
      }
      .section{
        &>div{
          margin: 1rem 0;
        }
        padding: 1rem;
        .label{
          font-weight: 500;
        }
      }
      .resource-description{
        text-align: left;
        padding: 0 1rem;
      }
    }
  }
}
