.search-bar {
  position: relative;
  display: inline-flex;
  margin-left: 12px;
  margin-right: 1rem;

  .search-btn {
    svg {
      stroke-width: 1.5;
      color: $text;
    }
  }
  .search-input {
    width: 420px;
  }
  .search-close {
    position: absolute;
    right: 0;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
    svg {
      width: 18px;
      height: 18px;
      stroke-width: 4;
      color: $secondary;
    }
  }
}
