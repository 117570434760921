// Ricerca
.free-search-wrapper {
  position: relative;
  .input-group-prepend {
    position: absolute;
    top: 0.25rem;

    .input-group-text {
      border: 0;
      background: transparent;
      svg{
        position: relative;
        top: -7px
      }
    }
  }
}

.free-search {
  border: 1px solid transparent;
  padding: 0.25em 1em 0.25em 2.5em;
  background: $ultralightgrey;
  border-radius: 50px;

  &:focus {
    border: 1px solid #e0e1e2;
    background: $ultralightgrey;
    outline: rgba(0, 0, 0, 0.87) none 0;
  }

  @include input-placeholder {
    color: $gray-400;
  }
}

// Material form
.bmd-form-group {
  &.hidden {
    display: none;
  }
  &.user_default_lang{
    label{
      top: 1rem;
      left: 0;
      font-size: 0.75rem;
    }
  }
}


.form-group {

  &.boolean {
    .switch {
      .form-check-label {
        position: absolute;
      }
    }
  }

  &.check_boxes {
    padding-top: 0;

    legend {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.26);
    }

    .switch {
      label {
        color: $gray-700;
      }
    }
  }
}

select.form-control:read-only, select.custom-file-control:read-only {
  background-image: linear-gradient(to top, #1967d2 2px, rgba(25, 103, 210, 0) 2px),
  linear-gradient(to top, rgba(0, 0, 0, 0.26) 1px, rgba(0, 0, 0, 0) 1px);
}

.was-validated .form-control:invalid, .was-validated .custom-file-control:invalid,
.form-control.is-invalid, .is-invalid.custom-file-control,
.was-validated select.form-control:invalid, .was-validated select.custom-file-control:invalid,
select.form-control.is-invalid, select.is-invalid.custom-file-control {
  border-color: inherit;
  padding-right: inherit;
  // #f44336, #1967d2
  background-image: linear-gradient(to top, #f44336 2px, rgba(25, 103, 210, 0) 2px),
                    linear-gradient(to top, rgba(244, 67, 54, 0.26) 1px, rgba(0, 0, 0, 0) 1px);
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
}
.was-validated .form-control:invalid:focus, .was-validated .custom-file-control:invalid:focus,
.form-control.is-invalid:focus, .is-invalid.custom-file-control:focus {
  border-color: inherit;
  box-shadow: none;
}

.uppy-FileInput-container {
  margin-bottom: 15px;
}

.uppy-FileInput-btn {
  @extend .btn;
  @extend .btn-raised;
  @extend .btn-primary;
  font-family: "Manrope", "Helvetica", "Arial", sans-serif;
}
