[data-resource-uppy] {

  .uppy-reset {
    position: absolute;
    top: 5em;
    right: 3em;
    z-index: 2000;
    color: #ccc;
  }

}