//app
$ultralightgrey: #f8f9fa;
$border-grey: #cecece;
$grey0: #eee;
//$lightgrey: #e0e1e2;
$lightgrey: #eaeef2;
//$mediumgrey: #d9dadb;
$mediumgrey: #dde4eb;
$grey: #546475;
$black: #1d2329;
$ultralightblue: #cddff4;
$devise-fg-actions: #212529;
$devise-fg-actions-hover: #212529FF;
$right-sidebar-color: #005b97;
$main-active: #46cca9;
$main-complementary: #f7b321;
$alert: #cb2330;
$menu-selected: #e8f0fe;
$menu-text-selected: #1967d2;
$menu-hovered: rgba(0, 0, 0, 0.04);
$link: #1967d2;

// Palette
$grey_light: #ececec;
$grey_ultralight: #f3f3f3;
$grey_white: #f9f9f9;
$grey_blue: #f1f6f9;
//$text_input: #0b4464;
$text_input: #2270a0;
$text: #1a1a1a;

$body-bg: #fff;
$bmd-label-color: #495057;
$theme-colors: (
    "primary": #1967d2,
    "secondary": #546475,
    "light": #9e9e9e,
);

@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import '~jquery-ui/themes/base/resizable';

//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-material-design/scss/core";

@import "~izitoast/dist/css/iziToast.min";
@import "~overlayscrollbars/css/OverlayScrollbars";
@import "~metismenujs/dist/metismenujs";
@import '~bootstrap-fileinput/scss/fileinput';

// Uppy
@import '~@uppy/core/dist/style';
@import "~@uppy/status-bar/dist/style";

@import "~select2";
@import "jquery.typeahead";
@import "./app/jquery-tagsinput/jquery.tagsinput-revisited";
@import "./app/uppy_customizations";
@import "./app/metismenujs_customizations";

@import "./app/mixins";
@import "./app/general";
@import "./app/landing";
@import "./app/devise";
@import "./app/account";
@import "./app/navbar";
@import "./app/sidebar";
@import "./app/forms";
@import "./app/search";
@import "./app/tables";
@import "./app/pagination";
@import "./app/folders";
@import "./app/payments";
@import "./app/users";
@import "./app/buttons";
@import "./jstree/style";
@import "~jquery-contextmenu/dist/jquery.contextMenu";

@import "cookie_law";
