@mixin landing{
  .main{
    height: 100%;
    background: black;
    color: white;
    padding-top: 0;
    a{
      color: white;
    }
    & > div{
      background: black;
      .header{
        border-top: 2rem solid rgba(255, 255, 255, 0.1);
        padding: 5px 3rem;
        font-size: 1rem;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.7);
        a{
          color: rgba(255, 255, 255, 0.7);
          &.login{
            margin-left: 3rem;
          }
        }
        .lang{
          font-weight: normal;
          a{
            color: rgba(255, 255, 255, 0.7);
            font-weight: 700;
            padding: 0;
            text-decoration: none;
          }
        }
      }
      .level-1{
        text-align: center;
        margin-bottom: 5rem;
        h1{
          font-size: 3.5rem;
          margin-bottom: 1rem;
        }
        h2{
          font-size: 1.2rem;
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .level-2{
        background-image: url('../../images/home_1.jpg');
        background-attachment: fixed;
        .text{
          background-color: rgba(0, 0, 0, 0.3);
          h3{
            font-size: 4rem;
            padding-top: 13rem;
            padding-bottom: 6rem;
            text-align: center;
          }
          h4{
            font-size: 1.5rem;
            padding-bottom: 11rem;
            text-align: center;
          }
        }
      }
      .level-3{
        text-align: center;
        padding: 3rem;
        h3{
          padding-top: 5rem;
          padding-bottom: 3rem;
          font-size: 3rem;
          font-weight: 400;
        }
        h4{
          font-size: 1rem;
          padding-bottom: 6rem;
        }
        h5{
          text-align: center;
          font-size: 1.5rem;
          padding-top: 4rem;
          font-weight: 700;
          line-height: 2rem;
        }
      }
      .level-4{
        text-align: center;
        padding-top: 4rem;
        background:  linear-gradient(140deg, #fafafa, #a2c4c9);
        color: $black;
        h3{
          font-size: 3rem;
          margin-bottom: 2rem;
        }
        h4{
          font-size: 1.2rem;
          line-height: 2.5rem;
          max-width: 30rem;
          margin: auto;
          text-align: left;
          padding-left: 1rem;
          padding-right: 1rem;
        }
        img{
          margin-top: 4rem;
          width: 100%;
        }
      }
      .level-5{
        background-image: url('../../images/home_6.jpg');
        background-position-x: 20%;
        background-size: cover;
        padding-left: 40%;
        padding-right: 10%;
        padding-top: 8rem;
        padding-bottom: 8rem;
        color: #fff;
        h3{
          font-size: 2.6rem;
          font-weight: normal;
          margin-bottom: 2rem;
        }
        p{
          padding-bottom: 1rem;
          line-height: 2.5rem;
          font-size: 1.2rem;
        }
        h4{
          font-weight: 700;
        }
      }
      .level-6{
        padding-top: 8rem;
        padding-left: 1rem;
        padding-right: 1rem;
        h2{
          text-align: center;
          font-size: 4.5rem;
          margin-bottom: 10rem;
        }
        #carousel_testimonials{
          .carousel-control-prev, .carousel-control-next{
            align-items: flex-start;
            justify-content: flex-start;
            .carousel-control-sym{
              font-size: 10rem;
              font-weight: 100;
            }
          }
          .carousel-control-next{
            justify-content: flex-end;
          }
          .carousel-inner{
            width: 65%;
            margin: auto;
            .carousel-item{
                font-weight: normal;
              .testimone{
                margin-bottom: 10rem;
                .data{
                  font-size: 1.4rem;
                  margin-top: 2rem;
                  .name, .country{
                    font-weight: 700;
                  }
                  .job{
                    color: rgba(255,255,255, 0.9);
                  }
                  .country{
                    margin-top: 2rem;
                  }
                }
                p{
                  color: rgba(255,255,255, 0.9);
                  padding-left: 3rem;
                  font-size: 1.2rem;
                  line-height: 2.3rem;
                }
              }
            }
          }
        }
      }
      .level-7{
        background-color: #001D1E;
        .text{
          padding-left: 12%;
          padding-right: 12%;
          display: flex;
          justify-content: center;
          height: 100%;
          flex-flow: column;
          h2{
            line-height: 4rem;
            font-size: 3rem;
          }
          h3{
            margin-top: 3rem;
            margin-bottom: 2rem;
            font-size: 2rem;
            line-height: 3rem;
            font-weight: normal;
          }
          a{
            font-size: 2rem;
            font-weight: 700;
          }
        }
        img{
          width: 100%;
        }
      }
      .level-registration{
        padding-top: 7rem;
        padding-bottom: 7rem;
        background-image: url('../../images/form-registration-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        h2{
          font-size: 3rem;
          margin-bottom: 2rem;
        }
        h3{
          line-height: 2rem;
          margin-bottom: 2rem;
          font-size: 1.2rem;
        }
        form{
          input, select{
            text-align: left;
            color: #fff;
            font-size: 1.2rem;
            border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
            background-image: none;
            &::placeholder{
              font-size: 1.2rem;
              color: rgba(255, 255, 255, 0.5);
            }
          }
          select{
            color: rgba(255, 255, 255, 0.5);
            option {
              color: $black;
              &:first-of-type {
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
          .btn-register{
            color: rgba(255, 255, 255, 0.7);
            width: 20rem;
            padding: 7px;
            font-size: 1.75rem;
            text-align: center;
            border: 2px solid  rgba(255, 255, 255, 0.7);
            background: transparent;
            margin-top: 2rem;
            text-transform: uppercase;
            &:hover{
              color: #fff;
              border: 2px solid  #fff;
            }
          }
          .form-group-invalid{
            .invalid-feedback{
              display: block !important;
              font-size: 1.5rem;
              color: $alert;
              font-weight: 700;
            }
          }
        }
      }
      .footer{
        color: rgba(255, 255, 255, 0.5);
	a {
	 color: rgba(255, 255, 255, 0.5);
	}
        text-align: center;
        padding-top: 3rem;
        .f-1{
          img{
            &.logo_a{
              width: 105px;
            }
          }
          margin-bottom: 3.5rem;
          h1{
            font-size: 3.5rem;
            color: #fff;
            margin-bottom: 1rem;
          }
          h2{
            font-size: 1.2rem;
          }
          h3{
            margin-top: 1.5rem;
            font-size: 1rem;
            padding: 0 10px;
          }
        }
        .f-3{
          margin: 8rem 0 3rem 0;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
          padding-top: 2rem;
          padding-left: 2rem;
          padding-right: 2rem;
          text-align: left;
          .policies{
            text-align: right;
            a{
              display: inline-block;
              color: rgba(255, 255, 255, 0.5);
              &:not(:first-child){
                padding-left: 2rem;
                border-left: 1px solid rgba(255, 255, 255, 0.5);
              }
              &:not(:last-child){
                padding-right: 2rem;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .main{
      & > div{
        .level-2{
          background-position-x: -18%;
        }
        .level-5{
          padding-right: 3rem;
        }
        .level-6{
          h2{
            margin-bottom: 8rem;
          }
          #carousel_testimonials{
            .carousel-inner{
              width: 77%;
              .carousel-item{
                .testimone{
                  margin-bottom: 5rem;
                  img{
                    width: 15rem;
                    display: block;
                    margin: auto;
                  }
                  p{
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
        .level-7{
          .text{
            h2{
              font-size: 2.5rem;
              line-height: 3.5rem;
            }
            h3{
              margin-top: 1rem;
              margin-bottom: 1rem;
              font-size: 1.8rem;
              line-height: 2.5rem;
            }
          }
        }
        .footer{
          padding-top: 3rem;
          .f-3{
            margin-top: 7rem;
            .policies{
              a{
                &:not(:first-child){
                  padding-left: 1rem;
                }
                &:not(:last-child){
                  padding-right: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .main{
      & > div{
        .level-2{
          background-position-x: -3%;
          .text{
            h3{
              font-size: 3.5rem;
              padding-top: 9rem;
              padding-bottom: 4rem;
            }
            h4{
              padding-bottom: 10rem;
            }
          }
        }
        .level-3{
          img{
            width: 14rem;
          }
        }
        .level-5{
          padding-top: 6rem;
          padding-bottom: 6rem;
        }
        .level-6{
          padding-top: 6rem;
          h2{
            margin-bottom: 6rem;
          }
          #carousel_testimonials{
            .carousel-control-prev, .carousel-control-next{
              .carousel-control-sym{
                font-size: 8rem;
              }
            }
            .carousel-inner{
              width: 80%;
              .carousel-item{
                .testimone{
                  img{
                    width: 14rem;
                  }
                  p{
                    width: 84%;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
        .level-7{
          .text{
            h2{
              font-size: 2rem;
              line-height: 3rem;
            }
            h3{
              margin-top: 0rem;
              margin-bottom: 1rem;
              font-size: 1.5rem;
              line-height: 2.2rem;
            }
            a{
              font-size: 1.5rem;
            }
          }
        }
        .footer{
          .f-3{
            .policies{
              a{
                &:not(:first-child){
                  padding-left: 0.7rem;
                }
                &:not(:last-child){
                  padding-right: 0.7rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .main{
      & > div{
        .header{
          padding-left: 2rem;
          padding-right: 2rem;
          a.login{
            margin-left: 2rem;
          }
        }
        .level-2{
          background-position-x: 45%;
        }
        .level-3{
          padding: 3rem 2rem;
          h3{
            padding-top: 3rem;
          }
          h4{
            padding-bottom: 4rem;
          }
          h5{
            padding-top: 2rem;
            margin-bottom: 5rem;
          }
        }
        .level-5{
          background-position-x: 27%;
          padding-left: 33%;
        }
        .level-6{
          padding-top: 5rem;
          h2{
            font-size: 3.8rem;
          }
          #carousel_testimonials{
            .carousel-inner{
              .carousel-item{
                .testimone{
                  img{
                    width: 18rem;
                  }
                  p{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .level-7{
          .text{
            padding: 5rem;
          }
        }
        .level-registration{
          padding: 2rem;
        }
        .footer{
          .f-3{
            .policies{
              text-align: left;
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .main{
      & > div{
        .header{
          padding: 5px  1rem;
          a.login{
            margin-left: 1.5rem;
          }
        }
        .level-1{
          h1{
            font-size: 3.3rem;
          }
        }
        .level-2{
          .text{
            h3{
              font-size: 3.5rem;
              padding-top: 6rem;
              padding-bottom: 3rem;
            }
            h4{
              padding-bottom: 7rem;
            }
          }
        }

        .level-5{
          background-position-x: 50%;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 5rem;
          padding-bottom: 5rem;
          background-size: cover;
          h3{
            font-size: 2.6rem;
          }
          h4{
            font-weight: 700;
          }
          p{
            line-height: 2.1rem;
          }
        }
        .level-6{
          padding-left: 0;
          padding-right: 0;
          h2{
            font-size: 2.6rem;
            margin-bottom: 5rem;
          }
          #carousel_testimonials{
            .carousel-control-prev, .carousel-control-next{
              .carousel-control-sym{
                font-size: 6rem;
              }
            }
            .carousel-inner{
              .carousel-item{
                .testimone{
                  img{
                    width: 15rem;
                  }
                }
              }
            }
          }
        }
        .level-7{
          .text{
            justify-content: left;
            padding: 3rem 2rem;
            h2{
              line-height: 2.5rem;
              font-size: 1.8rem;
            }
          }
          img{
            width: 100%;
          }
        }
        .level-registration{
          h2{
            font-size: 2.5rem;
          }
          h3{
            font-size: 1.1rem;
          }
          form{
            input{
              font-size: 1.1rem;
            }
            .btn-register{
              width: 15rem;
            }
          }
        }
        .footer{
          .f-3{
            //margin-left: 2rem;
            //margin-right: 2rem;
            .policies{
              a.terms{
                display: block;
                padding-left: 0;
                border: none;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
