.sidebar-nav {
  //background: #212529;
}
.sidebar-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .metismenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

//.sidebar-nav .metismenu li + li {
//  margin-top: 2px;
//}
//
//.sidebar-nav .metismenu li:first-child {
//  margin-top: 2px;
//}
//.sidebar-nav .metismenu li:last-child {
//  margin-bottom: 2px;
//}

.sidebar-nav .metismenu .mm-account {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sidebar-nav .metismenu > li {
  /*-webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding-right: 0.5rem;
}
.sidebar-nav .metismenu a {
  position: relative;
  display: block;
  padding: 8px 15px;
  color: $black;
  outline-width: 0;
  transition: all .2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-nav .metismenu a.has-arrow {
  padding-right: 2rem;
}
.sidebar-nav .metismenu a i.fa,
.sidebar-nav .metismenu a i.fas {
  margin-right: 0.4rem;
}
.sidebar-nav .metismenu .mm-account a img {
  margin-right: 0.4rem;
  margin-left: -0.3rem;
  width: 30px;
  height: 30px;
}

.sidebar-nav .metismenu ul a {
  padding: 5px 15px 5px 30px;
}

.sidebar-nav .metismenu ul ul a {
  padding: 5px 15px 5px 45px;
}

.sidebar-nav .metismenu li > a {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  &.has-arrow::after{
    border-color: transparent;
  }
}
.sidebar-nav .metismenu li.active > a {
  background: $menu-selected;
  color: $menu-text-selected;
}
//.sidebar-nav .metismenu a:focus,
//.sidebar-nav .metismenu a:active,
.sidebar-nav .metismenu a:hover {
  //color: #f8f9fa;
  text-decoration: none;
  background: $menu-hovered
}
