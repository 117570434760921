@mixin ellipsis-text($font-size, $line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin input-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
  &::-moz-placeholder {  /* Firefox 19+ */
    opacity: 1;
    @content;
  }
  /* Internet Explorer 10 */
  &:-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}
