.accounts{
  .block{
    padding: 2rem 1rem 4rem 1rem;
    border-bottom: 1px solid $grey0;
    span.modifica {
      display:inline-block;
      opacity:0.1;
      color:$black;
      background:#fff;
      border-radius: 12px;
      padding : 2px 4px;

    }
    &:hover{
      background-color:$ultralightgrey;
      span.modifica {
        display:inline-block;
        opacity: 1;
      }
    }
    .icon{
        font-size: 100px;
      svg{
        width: 100px;
        height: 100px;
      }
    }
    span.modifica {
      position: relative;
      left:-88px;
      font-weight:700;
    }
  }
  .account-title{
    font-size: 1.5em;
    font-weight: 600;
  }
  .password .account-title , .email .account-title{
    padding-top:2em;
  }
  i.placecard, svg.placecard {
    color:$lightgrey;
  }
}