body {
  font-family: "Manrope", "Helvetica", "Arial", sans-serif;
  font-size:14px;
}

svg {
  stroke-width: 1px;
  color: $black;
}


a, .btn-link{
  color: $link;
  &:not(.text-secondary){
    svg{
      color: $link;
    }
  }
}
.labels-block {
  margin: 0.6rem 0;

  > span {
    display: block;
    line-height: 1.15em;
  }
  span.first-label {
    margin-bottom: 0rem;
    font-size: 0.8em;
    color: #666;
  }
  span.value-label {
    margin-bottom: 1em;
    font-size: 1.25em;
    font-weight: 600;
    color: $black;
  }
  span.bolder-label {
    font-size: 0.9em;
    color:$black;
    font-weight: 600;
  }
}

.ui-autocomplete {
  position: absolute;
  z-index: 99999 !important;
  cursor: default;
  padding: 0;
  margin-top: 2px;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.ui-autocomplete>li {
  padding: 3px 20px;
}

.ui-autocomplete>li.ui-state-focus {
  background-color: #DDD;
}

.ui-helper-hidden-accessible {
  display: none;
}
.main{
  background-color: #FFF;
  & > div{
    background-color: #FFF;
  }
}
.text-link{
  color: $menu-text-selected;
}

.ajax-load{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  svg.spin{
    width: 100px;
    height: 100px;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
  }
}
.logged-in-home-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 86vh;
  height: -webkit-fill-available;
  .logo-box{
    margin-top: 2rem;
    background: #fff;
    color: #000;
    padding: 2rem;
    h1{
      font-size: 4.5rem;
      margin-bottom: 1rem;
    }
    h2{
      font-size: 1.5rem;
      //color: rgba(255, 255, 255, 0.7);
    }
  }
}