header.navbar {
  padding: 0;
  background: $white !important;
  border-bottom: 1px solid $border-grey !important;
  box-shadow: none;
  min-height: 64px;

  &.admin-navbar {
    border-bottom-color:$alert !important;
  }

  .navbar-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    width: 250px;
    //background: $grey_white;
  }
  .navbar-brand{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $gray-700;
        font-weight: 500;
        span {
          font-size: 1.3em;
          padding-left: 0.4em;
        }
      }
      &.active{
        > a {
          color: $black;
        }
      }

      > ul.dropdown-menu {
        width: 100%;
        .nav-item {
          .nav-link {
            padding: 0.5rem 0.75rem;
          }
        }
      }
    }
  }
  .session-links{
    padding-left: 1.5rem;
    padding-right: 2rem;
    a {
      color: $grey;
      font-size:12px;
      font-weight:500;
    }
  }
  .btn-icon-rounded {
  
    border: 0;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 9px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1;
    font-size: 1.1rem;
    
    &:active, &:hover {
      border: 0;
      color:#fff;
    }
    &.nav-link{
      padding: 9px;
    }
  }

  .navbar-search {
    padding-left: 3rem;

    .free-search {
      width: 30rem;
    }
  }

  .navbar-actions {
    margin-left: auto;
    padding-right: 1rem;
    .folder-actions, .resource-actions{
      a{
        font-size: 1.2rem;
      }
    }

  }

}
