.admin-users{
  .user-activity{
    a{
      text-transform: none;
    }
  }
  &.show{
    svg{
      &.checked{
        stroke-width: 4;
      }
    }
  }
}