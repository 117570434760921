$banner_background: #000;
$banner_text: #FFF;
$button_background: #1967d2;
$button_colour: #FFF;

.outer {
  background-color: rgba($banner_background, 0.85);
  font-size: 0.8em;
  font-family: verdana, arial, tahoma, sans-serif;
  padding: 1em 0;
  margin: 0;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  .inner {
    margin: 0 auto;
    width: 80%;
    position: relative;
    .text {
      color: $banner_text;
      display: block;
      float:left;
      width: 70%;
      line-height: 1.5em;
      b {
        color: $banner_text;
        font-weight: bold;
      }
    }
    .buttons {
      color: rgb(255, 255, 255);
      display:block;
      float:right;
      width: 25%;
      text-align: right;
      line-height: 1.2em;
      .accept {
        display:inline-block;
        padding: 0.25em 0.5em;
        border-radius: 3px;
        color: $button_colour;
        font-size: 0.85rem;
        font-weight: bold;
        text-decoration: none;
        margin-bottom:1em;
        background: $button_background;
        &:hover {
          background: lighten($button_background, 10%);
        }
      }
      .more_info{
        color: $banner_text;
        text-decoration: underline;
        display: block;
        &:hover {
          text-decoration-color: lighten($button_background, 10%);
        }
      }
    }
  }
}