.tagsinput {
  display: inline-block;
  box-sizing: border-box;
  background: transparent;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #556270;
  padding: 5px 5px 0 5px;
  border: 0;
  border-radius: 2px;
}

.tagsinput.focus {
	border-color: #ccc;
}

.tagsinput * {
	box-sizing: border-box;
}

.tagsinput .tag {
  position: relative;
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  margin: 0 5px 5px 0;
  padding: 5px 30px 5px 10px;
  font-size: 10px;
  font-weight: 600;
  background-color: #6c757d;
  line-height: 2em;
  text-transform: uppercase;
  border: 1px dotted #e0e1e2;
  border-radius: 0;
  color: #FFFFFF;
}

.tagsinput .tag .tag-remove {
	position: absolute;
	background: none;
	display: block;
	width: 30px;
	height: 30px;
	top: 0;
	right: 0;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	color: #aaa;
	line-height: 30px;
	padding: 0;
	border: 0;
}

.tagsinput .tag .tag-remove:before,
.tagsinput .tag .tag-remove:after {
	background: #aaa;
	position: absolute;
	display: block;
	width: 10px;
	height: 2px;
	top: 14px;
	left: 10px;
	content: '';
}

.tagsinput .tag .tag-remove:before {
	-webkit-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
}

.tagsinput .tag .tag-remove:after {
	-webkit-transform: rotateZ(-45deg);
	transform: rotateZ(-45deg);
}

.tagsinput div {
  display: inline-block;
}

.tagsinput div input {
	background: transparent;
	display: inline-block;
	//width: 100%;
	font-size: 14px;
	line-height: 20px;
	padding: 5px;
	border: 0 none;
	margin: 0 5px 5px 0;
}

.tagsinput div input.error {
	color: #aaa;
}

.tagsinput div input::-ms-clear {
	display: none;
}

.tagsinput div input::-webkit-input-placeholder {
	color: #ccc;
	opacity: 1.0;
}

.tagsinput div input:-moz-placeholder {
	color: #ccc;
	opacity: 1.0;
}

.tagsinput div input::-moz-placeholder {
	color: #ccc;
	opacity: 1.0;
}

.tagsinput div input:-ms-input-placeholder {
	color: #ccc;
	opacity: 1.0;
}
